<template>
  <div class="containerx">
    <v-row>
      <v-col cols="12">
        <div v-if="pageLoading" class="d-flex flex-column flex-grow-1">
          <SkeletonPreloader type="image" :count="3" classes="pa-1" />
        </div>
        <div
          v-else
          class="d-flex flex-row flex-grow-1 justify-space-between card flex-wrap"
        >
          <div class="d-flex flex-column flex-grow-1 pa-1">
            <v-card class=" pa-5 box-shadow-light" color="warning">
              <div class="d-flex flex-column flex-grow-1">
                <h3
                  class="white--text font-weight-bold font-size-lg text-uppercase ft"
                >
                  Total Shop Sales
                </h3>
                <p class="white--text ft font-weight-medium font-size-sm">
                  Overview of sales for shops made over period
                </p>
              </div>
              <span
                class="white--text ft font-weight-bold"
                style="font-size: 30px;"
              >
                {{
                  salesTotal.totalShopSales
                    | currencyFormat(
                      currency.length > 0 ? currency[0].code : "GHC"
                    )
                }}</span
              >
            </v-card>
          </div>
          <div class="d-flex flex-column flex-grow-1 pa-1">
            <v-card class=" pa-5 box-shadow-light" color="#0cdd14">
              <div class="d-flex flex-column flex-grow-1">
                <h3
                  class="white--text font-weight-bold font-size-lg text-uppercase ft"
                >
                  Total Lube Sales
                </h3>
                <p class="white--text ft font-weight-medium font-size-sm">
                  Overview of sales for lubes made over period
                </p>
              </div>
              <span
                class="white--text font font-weight-bold"
                style="font-size: 30px;"
              >
                {{
                  salesTotal.totalLubeSales
                    | currencyFormat(
                      currency.length > 0 ? currency[0].code : "GHC"
                    )
                }}</span
              >
            </v-card>
          </div>
          <div class="d-flex flex-column flex-grow-1 pa-1">
            <v-card class=" pa-5 box-shadow-light" color="blue">
              <div class="d-flex flex-column flex-grow-1">
                <h3
                  class="white--text font-weight-bold font-size-lg text-uppercase ft"
                >
                  Total Fuel Sales
                </h3>
                <p class="white--text ft font-weight-medium font-size-sm">
                  Overview of sales for fuels made over period
                </p>
              </div>
              <span
                class="white--text font font-weight-bold"
                style="font-size: 30px;"
              >
                {{
                  salesTotal.totalFuelSales
                    | currencyFormat(
                      currency.length > 0 ? currency[0].code : "GHC"
                    )
                }}</span
              >
            </v-card>
          </div>
        </div>
        <div class="d-flex flex-row flex-grow-1 mt-3">
          <div
            class="d-flex flex-column flex-grow-1 pa-1"
            style=" flex-basis: 45%;"
          >
            <h3 class=" font font-weight-medium font-size-md text-uppercase">
              Sales Variations Over Period
            </h3>
            <p class=" font blue-grey--text font-size-sm">
              Overview of all sales over a period
            </p>
            <v-progress-circular
              v-if="subLoading"
              indeterminate
              size="30"
              width="3"
            />

            <apexcharts
              ref="variationChart"
              v-show="!subLoading"
              class="d-flex box-shadow-light pt-2 pr-1"
              height="350"
              style="width: 100% !important;border-radius: 8px;"
              type="area"
              :options="salesVariationOptions"
              :series="salesVariationSeries"
            ></apexcharts>
          </div>
          <div class="d-flex flex-column pa-1" style="flex-basis: 35%;">
            <h3 class=" font font-weight-medium font-size-md text-uppercase">
              Total Sales Weight
            </h3>
            <p class=" font blue-grey--text font-size-sm">
              Overview of the weight of sales made for lubes,fuels, shops
            </p>
            <v-progress-circular
              v-if="pageLoading"
              indeterminate
              size="30"
              width="3"
            />
            <apexcharts
              v-else
              class="d-flex box-shadow-light"
              height="400"
              type="donut"
              :options="weightOptions"
              :series="weightSalesSeries"
            ></apexcharts>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-grow-1 justify-space-between mt-3 card flex-wrap"
        >
          <h3 class=" font font-weight-medium font-size-md text-uppercase">
            Sales Progress Over Period
          </h3>
          <p class=" font blue-grey--text font-size-sm">
            Overview of the progress of sales made for lubes,fuels, shops
          </p>
          <apexcharts
            class="d-flex box-shadow-light"
            height="400"
            type="line"
            :options="chartOptions"
            :series="seriesLine"
          ></apexcharts>
        </div>
        <div class="d-flex flex-row flex-grow-1 pt-2">
          <v-chip-group v-model="yearly" show-arrows>
            <v-chip
              v-for="(year, index) in years"
              :key="index"
              small
              label
              mandatory
              active-class="primary"
              color="white"
              class=" font font-weight-bold box-shadow-light"
              >{{ year.year }}</v-chip
            >
          </v-chip-group>
        </div>
        <div class="d-flex flex-row flex-grow-1">
          <div class="d-flex flex-column flex-grow-1">
            <h3 class=" font font-weight-medium font-size-md text-uppercase">
              Yearly Sales Of Fuels ({{
                `${new Date().getFullYear()}-01-01` | dateFormat
              }}
              - {{ `${new Date().getFullYear()}-12-31` | dateFormat }})
            </h3>
            <p class=" font blue-grey--text font-size-sm">
              General overview of yearly sales
            </p>

            <div class="d-flex flex-row flex-grow-1 flex-wrap">
              <v-card class=" box-shadow-light py-2 px-1">
                <v-tooltip
                  v-for="(day, index) in fuelYearlySales"
                  :key="index"
                  top
                  color="black"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      label
                      x-small
                      :color="day.totalAmount | heatColors(colors, 'fuels')"
                      style="margin:2px;height:25px;"
                    >
                    </v-chip>
                  </template>
                  <span class=" font font-weight-medium font-size-sm">
                    {{
                      day.totalAmount
                        | currencyFormat(
                          currency.length > 0 ? currency[0].code : "GHC"
                        )
                    }}
                    sales on {{ day.date | dateFormat }}</span
                  >
                </v-tooltip>
                <div class="d-flex flex-row flex-grow-1 justify-end pr-3 pt-5">
                  <span class="mx-2 font font-weight-medium font-size-sm"
                    >More</span
                  >
                  <v-chip
                    v-for="(color, index) in colors"
                    :key="index"
                    small
                    label
                    style="margin:1px"
                    :color="color"
                  ></v-chip>
                  <span class="mx-2 font font-weight-medium font-size-sm"
                    >Less</span
                  >
                </div>
              </v-card>
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1">
            <h3 class=" font font-weight-medium font-size-md text-uppercase">
              Yearly Sales Of Lubes ({{
                `${new Date().getFullYear()}-01-01` | dateFormat
              }}
              - {{ `${new Date().getFullYear()}-12-31` | dateFormat }})
            </h3>
            <p class=" font blue-grey--text font-size-sm">
              General overview of yearly sales
            </p>

            <div class="d-flex flex-row flex-grow-1 flex-wrap">
              <v-card class=" box-shadow-light py-2 px-1">
                <v-tooltip
                  v-for="(day, index) in lubesYearlySales"
                  :key="index"
                  top
                  color="black"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      label
                      x-small
                      :color="day.totalAmount | heatColors(colors, 'lubes')"
                      style="margin:2px;height:25px;"
                    >
                    </v-chip>
                  </template>
                  <span class=" font font-weight-medium font-size-sm">
                    {{
                      day.totalAmount
                        | currencyFormat(
                          currency.length > 0 ? currency[0].code : "GHC"
                        )
                    }}
                    sales on {{ day.date | dateFormat }}</span
                  >
                </v-tooltip>
                <div class="d-flex flex-row flex-grow-1 justify-end pr-3 pt-5">
                  <span class="mx-2 font font-weight-medium font-size-sm"
                    >More</span
                  >
                  <v-chip
                    v-for="(color, index) in colors"
                    :key="index"
                    small
                    label
                    style="margin:1px"
                    :color="color"
                  ></v-chip>
                  <span class="mx-2 font font-weight-medium font-size-sm"
                    >Less</span
                  >
                </div>
              </v-card>
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1">
            <h3 class=" font font-weight-medium font-size-md text-uppercase">
              Yearly Sales Of Shops ({{
                `${new Date().getFullYear()}-01-01` | dateFormat
              }}
              - {{ `${new Date().getFullYear()}-12-31` | dateFormat }})
            </h3>
            <p class=" font blue-grey--text font-size-sm">
              General overview of yearly sales
            </p>

            <div class="d-flex flex-row flex-grow-1 flex-wrap">
              <v-card class=" box-shadow-light py-2 px-1">
                <v-tooltip
                  v-for="(day, index) in shopYearlySales"
                  :key="index"
                  top
                  color="black"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      label
                      x-small
                      :color="day.totalAmount | heatColors(colors, 'shops')"
                      style="margin:2px;height:25px;"
                    >
                    </v-chip>
                  </template>
                  <span class=" font font-weight-medium font-size-sm">
                    {{
                      day.totalAmount
                        | currencyFormat(
                          currency.length > 0 ? currency[0].code : "GHC"
                        )
                    }}
                    sales on {{ day.date | dateFormat }}</span
                  >
                </v-tooltip>
                <div class="d-flex flex-row flex-grow-1 justify-end pr-3 pt-5">
                  <span class="mx-2 font font-weight-medium font-size-sm"
                    >More</span
                  >
                  <v-chip
                    v-for="(color, index) in colors"
                    :key="index"
                    small
                    label
                    style="margin:1px"
                    :color="color"
                  ></v-chip>
                  <span class="mx-2 font font-weight-medium font-size-sm"
                    >Less</span
                  >
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { currencyFormat } from "../../filters/currencyFormat";
import { roundAccurately } from "../utils/resolvers";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import { debounce } from "lodash";

export default {
  name: "SalesOverview",
  props: {
    years: {
      type: Array,
      default: () => []
    },
    currency: { type: Array, default: () => [] },
    period: {
      type: Object,
      default: null
    },
    range: {
      type: Array,
      default: () => []
    },
    branchId: {
      type: String,
      default: null
    }
  },
  components: {
    SkeletonPreloader: () => import(`../../components/SkeletonPreloader`),
    SnackBar: () => import(`../../components/SnackBar.vue`)
  },
  computed: {
    ...mapGetters({
      pageLoading: "getIsPageLoading",
      subLoading: "getIsSubLoading",
      yearlySales: "reports/getAllYearlySales",
      salesTotal: "reports/getSalesTotal",
      salesVariations: "reports/getSalesVariations"
    })
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      yearly: 0,
      weightOptions: {
        labels: ["Fuel Sales", "Lubes Sales", "Shop Sales"],
        chart: {
          type: "donut",
          events: {}
        }
      },
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          stacked: false
        },
        dataLabels: {
          enabled: false
        },

        xaxis: {
          categories: []
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm"
          }
        }
      },
      salesVariationOptions: {
        chart: {
          height: 350,
          type: "area"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type: "Date",
          categories: []
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm"
          }
        }
      },
      weightSalesSeries: [],
      colors: [
        "#00ac07",
        "#00ac07ab",
        "#00ac076b",
        "rgba(0, 172, 7, 0.25)",
        "rgba(20, 176, 26, 0.2"
      ],
      daysInYear: [],
      lubesYearlySales: [],
      fuelYearlySales: [],
      shopYearlySales: [],
      salesVariationSeries: [
        {
          name: "Fuel Sales",
          data: []
        },
        {
          name: "Lube Sales",
          data: []
        },
        {
          name: "Shop Sales",
          data: []
        }
      ],
      seriesLine: [
        { name: "Fuel Sales", data: [] },
        {
          name: "Lube Sales",
          data: []
        },
        {
          name: "Shop Sales",
          data: []
        }
      ]
    };
  },
  filters: {
    heatColors(value, colors, type) {
      switch (type) {
        case "fuels":
          if (Number(value) > 120000) {
            return colors[0];
          } else if (Number(value) > 80000 && Number(value) <= 120000) {
            return colors[1];
          } else if (Number(value) > 40000 && Number(value) <= 80000) {
            return colors[2];
          } else if (Number(value) > 20000 && Number(value) <= 40000) {
            return colors[3];
          } else if (Number(value) > 0 && Number(value) <= 20000) {
            return colors[4];
          } else return "";

        case "lubes":
          if (Number(value) > 5000) {
            return colors[0];
          } else if (Number(value) > 3000 && Number(value) <= 5000) {
            return colors[1];
          } else if (Number(value) > 2000 && Number(value) <= 3000) {
            return colors[2];
          } else if (Number(value) > 1000 && Number(value) <= 2000) {
            return colors[3];
          } else if (Number(value) > 0 && Number(value) <= 1000) {
            return colors[4];
          } else return "";
        case "shops":
          if (Number(value) > 5000) {
            return colors[0];
          } else if (Number(value) > 3000 && Number(value) <= 5000) {
            return colors[1];
          } else if (Number(value) > 2000 && Number(value) <= 3000) {
            return colors[2];
          } else if (Number(value) > 1000 && Number(value) <= 2000) {
            return colors[3];
          } else if (Number(value) > 0 && Number(value) <= 1000) {
            return colors[4];
          } else return "";
      }
    },
    dateFormat(value) {
      return moment(value).format("Do MMMM, YYYY");
    },
    currencyFormat
  },
  watch: {
    branchId: debounce(function(id) {
      if (this.daysInYear.length > 0) {
        this.$store.dispatch("reports/yearlySales", {
          year: new Date().getFullYear(),
          branchId: id
        });
        this.$store.dispatch("reports/salesTotalPerPeriod", {
          ...this.period,
          branchId: id
        });
        this.$store.dispatch("reports/salesVariationsOverPeriod", {
          ...this.period,
          branchId: id
        });
      }
    }, 100),
    salesVariations(value) {
      this.salesVariationOptions = {
        ...this.salesVariationOptions,
        xaxis: {
          categories: [
            ...new Set([
              ...value.fuelSales.map(sale =>
                moment(sale.salesDate).format("YYYY-MM-DD")
              ),
              ...value.lubeSales.map(sale =>
                moment(sale.date).format("YYYY-MM-DD")
              ),
              ...value.shopSales.map(sale =>
                moment(sale.salesDate).format("YYYY-MM-DD")
              )
            ])
          ]
        }
      };
      this.salesVariationSeries[0].data = value.fuelSales.map(sale =>
        roundAccurately(sale.totalAmount, 2)
      );
      this.salesVariationSeries[1].data = value.lubeSales.map(sale =>
        roundAccurately(sale.totalAmount, 2)
      );
      this.salesVariationSeries[2].data = value.shopSales.map(sale =>
        roundAccurately(sale.salesAmount, 2)
      );

      // sales progress in line chart
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: [
            ...new Set([
              ...value.fuelSales.map(sale =>
                moment(sale.salesDate).format("YYYY-MM-DD")
              ),
              ...value.lubeSales.map(sale =>
                moment(sale.date).format("YYYY-MM-DD")
              ),
              ...value.shopSales.map(sale =>
                moment(sale.salesDate).format("YYYY-MM-DD")
              )
            ])
          ]
        }
      };
      this.seriesLine[0].data = value.fuelSales.map(sale =>
        roundAccurately(sale.totalAmount, 2)
      );
      this.seriesLine[1].data = value.lubeSales.map(sale =>
        roundAccurately(sale.totalAmount, 2)
      );
      this.seriesLine[2].data = value.shopSales.map(sale =>
        roundAccurately(sale.salesAmount, 2)
      );
    },
    salesTotal: debounce(function(total) {
      const { totalFuelSales, totalShopSales, totalLubeSales } = total;
      this.weightSalesSeries = [
        Number(totalFuelSales),
        Number(totalLubeSales),
        Number(totalShopSales)
      ];
    }, 400),
    yearlySales(sales) {
      const { lubes, fuels, shops } = sales;
      this.daysInYear = this.getDatesForEntireYear(this.yearly);
      this.lubesYearlySales = this.daysInYear.map(date => {
        const lubeSale = lubes.find(lube => lube.date === date);
        return {
          date,
          ...(lubeSale
            ? {
                ...lubeSale
              }
            : { totalAmount: 0, quantitySold: 0 })
        };
      });
      this.fuelYearlySales = this.daysInYear.map(date => {
        const fuelSale = fuels.find(fuel => fuel.date === date);
        return {
          date,
          ...(fuelSale
            ? {
                ...fuelSale
              }
            : { totalAmount: 0, totalLiterSold: 0 })
        };
      });
      this.shopYearlySales = this.daysInYear.map(date => {
        const shopSale = shops.find(shop => shop.date === date);
        return {
          date,
          ...(shopSale
            ? {
                ...shopSale
              }
            : { totalAmount: 0, cashDeposit: 0 })
        };
      });
    },
    period: debounce(function(date) {
      if (date !== null) {
        this.$store.dispatch("reports/salesTotalPerPeriod", {
          ...date,
          branchId: this.branchId
        });
        this.$store.dispatch("reports/salesVariationsOverPeriod", {
          ...date,
          branchId: this.branchId
        });
      }
    }, 400),
    yearly: debounce(function(index) {
      this.$store.dispatch("reports/yearlySales", {
        year: this.years[index]?.year,
        branchId: this.branchId
      });
    }, 400),
    range(dates) {
      const [startDate, endDate] = dates;
      if (dates.length > 1 && moment(startDate).isAfter(moment(endDate))) {
        this.$store.dispatch(
          "showSnackBar",
          {
            message: `Invalid date range provided`,
            status: "red",
            snackBar: true
          },
          { root: true }
        );
        return;
      }
      if (dates.length === 2) {
        this.$store.dispatch("reports/salesTotalPerPeriod", {
          query: `?hasRange=true&startDate=${startDate}&endDate=${endDate}`,
          number: 0,
          type: "custom",
          branchId: this.branchId
        });

        this.$store.dispatch("reports/salesVariationsOverPeriod", {
          query: `?hasRange=true&startDate=${startDate}&endDate=${endDate}`,
          number: 0,
          type: "custom",
          branchId: this.branchId
        });
      }
    }
  },
  methods: {
    getDatesForEntireYear(yearIndex) {
      return new Array(366).fill(0).map((item, index) => {
        let yearToDeductOrAdd =
          Number(
            this.years.find((period, index) => index === yearIndex)?.year ?? 0
          ) - Number(moment().format("YYYY"));
        let yearToStart =
          yearToDeductOrAdd >= 0
            ? moment(`${new Date().getFullYear()}-01-01`).add(
                yearToDeductOrAdd,
                "years"
              )
            : moment(`${new Date().getFullYear()}-01-01`).subtract(
                Math.abs(yearToDeductOrAdd),
                "years"
              );
        return yearToStart.add(index, "day").format("YYYY-MM-DD");
      });
    }
  },
  created() {
    this.yearly = this.years.findIndex(
      period => period.year?.toString() === moment().format("YYYY")
    );
  },
  mounted() {}
};
</script>
